<template>
  <div class="login_container">
    <div class="login_box">
      <!-- title -->
      <div class="title-logo">
        <img src="../assets/img/logo-ht-title.png" style="width:60%" />
      </div>
      <!-- 登录表单 -->
      <div class="login_form">
        <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" label-width="0px">
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              placeholder="用户名"
              prefix-icon="iconfont icon-denglu"
            >
          </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              placeholder="密码"
              type="password"
              prefix-icon="iconfont icon-mima"
            ></el-input>
          </el-form-item>

<!--          <el-form-item prop="verifyCode" style="display: flex;width:100%">-->
<!--            <el-input-->
<!--              style="width: 70%"-->
<!--              v-model="loginForm.verifyCode"-->
<!--              placeholder="验证码"-->
<!--              prefix-icon="iconfont icon-mima"-->
<!--            ></el-input>-->
<!--            <div @click="refreshCode" style="cursor:pointer; width: 30%;height: 42px;" title="点击切换验证码" >-->
<!--              <s-identify :identifyCode="identifyCode"></s-identify>-->
<!--            </div>-->
<!--            </el-form-item>-->
        </el-form>
        <!-- <div class="tips">
          <el-checkbox v-model="isRemember">记住我</el-checkbox>
          <span style="color: #1890ff;font-size: 13px;">忘记密码？</span>
        </div> -->
        <div class="operation-button">
          <div class="login-btn">
            <el-button type="primary" style="width: 100%;background-color: #1890ff;margin-bottom: 1rem;" @click="login">登&nbsp;录</el-button>
          </div>
          <!-- <div class="reset-btn">
            <el-button style="width:100%;" @click="resetLoginForm">重&nbsp;置</el-button>
          </div> -->

        </div>
      </div>

      <!-- 头像区域 -->

      <!-- <div class="avatar_box">
        <img src="../assets/img/login.jpg" alt="" />
      </div> -->

      <!-- 登录表单区 -->
      <!-- 想拿到组件的实例对象 ref -->
      <!-- <el-form
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
        label-width="0px"
        class="login_form"
      >
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            placeholder="用户名"
            prefix-icon="iconfont icon-denglu"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            placeholder="密码"
            type="password"
            prefix-icon="iconfont icon-mima"
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" round @click="login">登录</el-button>
          <el-button type="info" round @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form> -->
    </div>
  </div>
</template>

<script>
import sIdentify from '../components/VerifyCode/index.vue'
export default {
  name: "mlogin",
  components: {sIdentify},
  data() {
    return {
      loginForm: {
        //登录表单的数据绑定对象
        username: "",
        password: "",
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 4 到 15 个字符",
            trigger: "blur",
          },
        ]
        // verifyCode: [
        // { required: true, message: "请输入验证码", trigger: "blur" },
        //   {validator: this.validateCaptcha,trigger: 'blur'}
        // ]
      },
      isRemember:false,
      identifyCode: "",
      identifyCodes: ['0','1','2','3','4','5','6','7','8','9'], //根据实际需求加入自己想要的字符
    };
  },
  created() {
    console.log('版本号:w1.0')

  },
  mounted(){
    this.refreshCode()
  },
  methods: {
    // 校验验证码是否正确
    // validateCaptcha(rule,value,callback){
    //   if(this.identifyCode != value){
    //     callback(new Error('验证码不正确'));
    //   }else{
    //     callback()
    //   }
    // },
     // 生成随机数
     randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length-1)]
      }
    },

    // 重置登录表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$axios
          .post("/user/login", {
              UserName: this.loginForm.username,
              Password: this.loginForm.password,
          })
          .then((req) => {
            if (req.code != 200) {
              return this.$message.error("登录失败");
            }
            this.$message.success("登录成功");
            // 本地  vuex
            let token = req.data.token
            this.$store.commit('LOGIN_IN', token)
            window.sessionStorage.setItem('userName', req.data.Name)
            window.sessionStorage.setItem('userId', req.data.ID)
            if (typeof (req.data.IsAdmin)==='object'&&(!req.data.合作人员||req.data.合作人员.length === 0)){
              let isAdmin=req.data.IsAdmin.data[0]||0
              if (isAdmin){
                window.sessionStorage.setItem('isAdmin', "1")
                this.$router.push("/report");
                return
              }
            }
            window.sessionStorage.setItem('qudaoID', req.data.合作人员 && req.data.合作人员.length > 0 ? req.data.合作人员[0].AuditID : '')

            window.sessionStorage.setItem('companyId', req.data.合作人员 && req.data.合作人员.length > 0 ? req.data.合作人员[0].隶属ID : '')
            window.sessionStorage.setItem('companyName', req.data.合作人员 && req.data.合作人员.length > 0 ? req.data.合作人员[0].隶属名称 : '')
            window.sessionStorage.setItem('cooperType',  req.data.合作人员 && req.data.合作人员.length > 0 ? req.data.合作人员[0].身份类型 : '')

            window.sessionStorage.setItem('isAdmin', "0")
            if (req.data.合作人员[0].身份类型 == '4') {
              window.sessionStorage.setItem('isAdmin', "1")
            }
            this.$router.push("/message");
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login_container {
  height: 100vh;
  background-image: url('../assets/img/bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.login_box {
  width: 35%;
  background-color: #fff;
  border-top: 8px solid #1890ff;
  /* border-radius: 15px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title-logo{
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .login_form{
    margin:40px 130px 0px 130px;
    .operation-button{
      margin-top: 0.5rem;
      margin-bottom: 1.5rem
    }
    .tips{
      display: flex;
      justify-content: space-between;
    }
  }
}
.el-form-item {
  margin-bottom: 18px;
}
::v-deep .el-form-item__content{
  display: flex;
  width: 100%;
}
::v-deep .el-input__inner {
      border-radius: 0px;
      border-top-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 1px;
      background-color: #fff !important;
      /*outline: medium;*/
    }

</style>
